import { GetServerDataProps, GetServerDataReturn, HeadFC, navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { Contact } from '../models/contact';

import axios from 'axios';
import Heading from '../components/Heading';
import Item from '../components/Item';
import Img1 from '../images/img1.png';
import Img2 from '../images/img2.png';
import Img3 from '../images/img3.png';
import KinaliLogo1 from '../images/logo-kinali-1.svg';
import KinaliLogo2 from '../images/logo-kinali-2.svg';

interface ServerData {
    contact: Contact;
    campaignId: string;
    redirect: boolean;
}

const IndexPage: React.FC<{ serverData: ServerData }> = ({ serverData }) => {
    useEffect(() => {
        if (serverData.redirect) {
            navigate('/404');
        }
    }, []);

    const attr = `?utm_source=emailing&utm_medium=email&utm_campaign=${serverData.campaignId}&utm_id=${serverData.campaignId}&utm_content=${serverData?.contact?.email}`;
    return serverData.redirect ? null : (
        <div className='flex flex-col items-center justify-center'>
            <div className='max-w-[960px] px-5 md:px-10 lg:px-16'>
                <main>
                    <section className='min-h-screen flex flex-col justify-between'>
                        <img src={KinaliLogo1} alt='Kinali Logo' className='w-16 md:w-24' />

                        <div className='flex items-center justify-center flex-col'>
                            <div className='rounded-full shadow-[0px_0px_14px_0px_rgba(0,0,0,0.2)] px-10 py-4 md:py-8 flex items-center justify-center'>
                                <p className='text-[20px] md:text-[35px] font-bold text-center'>
                                    {serverData.contact.companyName}
                                </p>
                            </div>
                            <p className='text-[35px] font-bold text-center my-1 md:my-4'>a</p>
                            <div className='rounded-full shadow-[0px_0px_14px_0px_rgba(0,0,0,0.2)] px-24 py-3 md:py-5 flex items-center justify-center'>
                                <img
                                    src={KinaliLogo2}
                                    alt='Test-it-off Logo'
                                    className='w-24 md:w-32'
                                />
                            </div>
                        </div>
                        <p className='text-[6px] leading-4 md:text-[16px] md:leading-8'>&nbsp;</p>
                        <h1 className='text-[35px] font-bold text-center'>by si mohly rozumět.</h1>
                        <p className='text-[6px] leading-4 md:text-[16px] md:leading-8'>&nbsp;</p>

                        <div className='flex flex-col items-center'>
                            <p className='text-[20px] leading-8 text-center'>
                                Na základě Vašeho výrobního programu se domníváme, že by pro Vás
                                mohla být{' '}
                                <span className='font-bold'>
                                    kontrola kvality materiálů a výrobků z extrudovaného plastu
                                </span>{' '}
                                důležitou součástí výrobního procesu.
                            </p>
                            <p className='text-[6px] leading-4 md:text-[16px] md:leading-8'>
                                &nbsp;
                            </p>
                            <p className='text-[6px] leading-4 md:text-[16px] md:leading-8'>
                                &nbsp;
                            </p>
                            <a
                                className='text-white md:text-[16px] lg:text-[20px] font-bold text-center py-2 md:py-4 lg:py-6 px-14 lg:px-20 bg-[#2AB4C5] hover:bg-[#5fcfdd]'
                                href='#pokracovat'
                            >
                                To souhlasí, řekněte mi víc
                            </a>
                        </div>
                        <div className='flex flex-col items-center'>
                            <p className='text-[6px] leading-4 md:text-[16px] md:leading-8'>
                                &nbsp;
                            </p>
                            <span className='font-bold text-base md:text-xl'>- - -</span>
                            <p className='text-[6px] leading-4 md:text-[16px] md:leading-8'>
                                &nbsp;
                            </p>
                        </div>
                    </section>

                    <section id='pokracovat'>
                        <p className='text-[6px] leading-4 md:text-[16px] md:leading-8'>&nbsp;</p>
                        <p className='text-[6px] leading-4 md:text-[16px] md:leading-8'>&nbsp;</p>
                        <p className='text-[16px] leading-8'>
                            Ať už jde o{' '}
                            <span className='font-bold'>
                                rozměry, barevnou stálost u všech vyrobených kusů či jistotu, že je
                                povrch vašich výrobků bez vad
                            </span>
                            , všechny tyto parametry zvládnou KINALI optická kontrolní zařízení
                            vybavená umělou inteligencí kontrolovat lépe a rychleji než lidé.
                        </p>
                        <p className='text-[16px] leading-8'>&nbsp;</p>

                        <p className='text-[16px] leading-8'>
                            Kontrola kvality člověkem podléhá mnoha faktorům, jež zvyšují nestálost
                            a subjektivitu výsledků. Patří mezi ně:
                        </p>
                        <ul className='list-disc mt-[1em] mb-[1em] pl-[40px]'>
                            <li>aktuální zdravotní stav,</li>
                            <li>stav zraku,</li>
                            <li>rozdílné světelné podmínky,</li>
                            <li>psychické rozpoložení,</li>
                            <li>únava atd.</li>
                        </ul>
                        <p className='text-[16px] leading-8'>&nbsp;</p>

                        <p className='text-[16px] leading-8'>
                            Z praxe víme, že kvůli těmto faktorům lidský kontrolor stejný výrobek
                            často zhodnotí ve dvou případech odlišně.
                        </p>
                        <p className='text-[16px] leading-8'>&nbsp;</p>

                        <p className='text-[16px] leading-8'>
                            <span className='font-bold'>
                                Automatizace kontroly kvality produktů z plastu
                            </span>{' '}
                            může všechny tyto neduhy odstranit jedním řešením. Každý stroj, který
                            pro klienty stavíme, je navržen na míru. Díky tomu dosahujeme významných
                            úspor a maximální jednoduchosti v podobě plně automatické práce, a to
                            včetně předávání výsledků nadřazeným MES systémům.
                        </p>
                    </section>

                    <section>
                        <Heading title='Co dokážeme kontrolovat' />
                        <div className='flex flex-wrap justify-center sm:justify-between gap-12'>
                            <Item
                                imageSrc={Img1}
                                title='Kontrola rozměrů'
                                href={`https://www.kinali.cz/cs/opticka-kontrola-kvality/kontrola-rozmeru/${attr}`}
                            />
                            <Item
                                imageSrc={Img2}
                                title='Kontrola povrchu'
                                href={`https://www.kinali.cz/cs/opticka-kontrola-kvality/kontrola-povrchu/${attr}`}
                            />
                            <Item
                                imageSrc={Img3}
                                title='Kontrola barev'
                                href={`https://www.kinali.cz/cs/opticka-kontrola-kvality/kontrola-barev/${attr}`}
                            />
                        </div>
                        <p className='text-[16px] leading-8'>&nbsp;</p>
                        <p className='text-[16px] leading-8'>
                            Kontrolovat dokážeme nekonečné pásy materiálu i konkrétní kusy plastu či
                            hotových produktů. Záleží jen na Vašich konkrétních požadavcích.
                        </p>
                    </section>

                    <section>
                        <Heading title='Proč by Vám optická kontrola kvality mohla přijít vhod' />
                        <ul className='list-disc mt-[1em] mb-[1em] pl-[40px]'>
                            <li>
                                Zajistí vám nulové reklamace produktů a díky tomu velmi rychlou
                                návratnost investice do zařízení (už od jednoho roku).
                            </li>
                            <li>
                                Kontrolovat lze v režimu 24/7 (včetně „dark factory“) bez typických
                                nákladů na vícesměnný provoz (příplatky za víkendy a svátky, práci v
                                noci apod.).
                            </li>
                            <li>
                                Kontrola strojem nepodléhá vlivům zdravotního stavu, psychické
                                pohody či emocí. Výsledky kontroly jsou vždy a za všech okolností
                                objektivní.
                            </li>
                            <li>
                                Díky špičkové optice lze kontrolovat i vady, rozměrové odchylky či
                                barevné nestálosti nepostřehnutelné lidským okem.
                            </li>
                            <li>
                                Stroj Vám nikdy neodejde, neonemocní a nebude chtít zvýšit plat.
                                Nebude sabotovat normy pro rychlost kontroly a poskytne Vám
                                kvalitativní úroveň kontroly, která u člověka často není možná.
                            </li>
                        </ul>
                    </section>

                    <section>
                        <Heading title='Proč využít KINALI' />
                        <ul className='list-disc mt-[1em] mb-[1em] pl-[40px]'>
                            <li>
                                Hardware i software pod jednou střechou. Máme vlastní inženýry i tým
                                vývojářů software. Ke spolupráci na finálním řešení Vám tak stačí
                                jeden subjekt.
                            </li>
                            <li>
                                Zakládáme si na minimalistických řešeních, která přináší maximální
                                užitek s co nejnižšími možnými náklady.
                            </li>
                            <li>
                                Naše řešení jsou vždy připravená na průmysl 4.0. Lze je zapojit do
                                dalších výrobních uzlů či ovládat pomocí MES/ERP systémů.
                            </li>
                            <li>
                                Získáte online přístup k datům a výsledkům kontrol, ať už jste
                                kdekoliv.
                            </li>
                            <li>
                                Jsme stabilní česká firma se 17letou tradicí. Při jakýchkoli
                                problémech v budoucnu Vám tak budeme stále na blízku.
                            </li>
                        </ul>
                        <p className='text-[16px] leading-8'>&nbsp;</p>
                    </section>
                    <section>
                        <p className='text-[16px] leading-8'>
                            <span className='font-bold'>
                                Ještě stále váháte? Podívejte se na konkrétní případy našich klientů
                                a způsoby, jak jsme je vyřešili.
                            </span>{' '}
                            Nebo se nám rovnou nezávazně ozvěte. U dobré kávy probereme Vaše
                            požadavky a naše možnosti, jak Vám pomoci.
                        </p>
                    </section>
                    <section className='flex flex-col md:flex-row items-center justify-center text-center font-bold gap-10 mt-12 mb-24'>
                        <a
                            target='_blank'
                            className='bg-[#2AB4C5] text-white py-5 md:w-2/5 w-11/12 hover:bg-[#5fcfdd]'
                            href={`https://www.kinali.cz/cs/opticka-kontrola-kvality/${attr}`}
                        >
                            Další informace a příklady řešení{' '}
                        </a>
                        <a
                            target='_blank'
                            className='bg-[#E8E6E6] text-[#2AB4C5] py-5 md:w-2/5 w-11/12 hover:bg-[#f4f3f3]'
                            href={`https://www.kinali.cz/cs/kontakt/${attr}`}
                        >
                            Kontaktovat pro nezávaznou schůzku
                        </a>
                    </section>
                </main>

                <footer className='border-t-2 border-[#2AB4C5]'>
                    <p className='text-center text-[14px] leading-5 mt-1 mb-10'>
                        <a
                            target='_blank'
                            href={`https://www.kinali.cz/cs/${attr}`}
                            className='underline'
                        >
                            KINALI
                        </a>{' '}
                        | Cejl 37/62, 602 00 Brno | e-mail:{' '}
                        <a href='mailto:info@kinali.cz' className='underline'>
                            info@kinali.cz
                        </a>{' '}
                        | tel.:{' '}
                        <a href='tel:+420511112218' className='underline'>
                            +420 511112218
                        </a>
                    </p>
                </footer>
            </div>
        </div>
    );
};

export default IndexPage;

export const Head: HeadFC = () => <title>KINALI</title>;

export async function getServerData(data: GetServerDataProps): GetServerDataReturn {
    // const mockContact = {
    //     companyName: 'IZOL-PLASTIK, s.r.o.',
    //     email: 'info@izolplastik.cz',
    // } as Contact;
    // return {
    //     status: 200,
    //     props: {
    //         contact: mockContact,
    //         redirect: false,
    //     },
    // };
    if (data.query && data.query.c !== undefined) {
        const contactId = data?.query['c'] as string;
        const campaignId = (data?.query['utm_campaign'] as string) ?? 'plasty-cr-1';
        try {
            const endpoint: string = `http://kinalisoft-marketing-webapi:5309/companies/${contactId}`;
            const response = await axios.get<Contact>(endpoint);
            return {
                status: 200,
                props: {
                    contact: response.data,
                    campaignId: campaignId,
                    redirect: false,
                },
            };
        } catch (error) {}
    }
    return {
        status: 200,
        props: {
            contact: undefined,
            campaignId: undefined,
            redirect: true,
        },
    };
}
